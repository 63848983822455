<style scoped>
#login-container {
  height: 100vh;
}
.diagonal-box {
  position: relative;
  background-image: linear-gradient(210deg, #2bd9eb 50%, #043561 50%);
}
.version {
  color: #2bd9eb;
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 10px;
}
</style>
<template>
  <div id="login-container" class="diagonal-box d-flex flex-column align-center justify-center">
    <v-card class="pa-10 flex-shrink-1" :max-width="$vuetify.breakpoint.smAndUp ? 600 : 375">
      <v-card-title>
        <v-img src="../assets/Telescope.svg" alt="" ></v-img>
      </v-card-title>
      <v-card-subtitle class="mt-1 text-center">
        <h2 class="h6">Personal Injury Portal</h2>
      </v-card-subtitle>
      <v-card-text @keydown.enter="login">
        <v-form ref="form" v-model="formValid">
          <v-text-field filled dense
                        label="email"
                        v-model="email"
                        :rules="[rules.required, rules.email]" />

          <v-text-field filled dense
                        label="password"
                        :type="showPassword ? 'text' : 'password'"
                        v-model="password"
                        :rules="[rules.required]">
            <template #append>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn icon small v-on="on" @click="showPassword = !showPassword">
                    <v-icon small v-if="showPassword">fa-eye-slash</v-icon>
                    <v-icon small v-else>fa-eye</v-icon>
                  </v-btn>
                </template>
                <span v-if="showPassword">hide password</span>
                <span v-else>show password</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="flex-column">
        <v-btn :loading="loading" block @click="login" color="primary">login</v-btn>
        <div class="align-self-start mt-2">
          Need help signing in? <v-btn text color="accent" @click="showReset = true">reset password</v-btn>
        </div>
        <v-row>
          <v-col>
            <v-spacer />
            <span class="version">Version: {{ version }}</span>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <div class="white--text mt-2">
      Don't have an account? <v-btn text color="accent" :to="{ name: 'Register' }">Sign up</v-btn>
    </div>
    <ResetPassword v-if="showReset" @close="showReset = false" />
  </div>
</template>
<script>
import RulesMixin from '../mixins/RulesMixin'
import ResetPassword from '../components/Login/ResetPassword'

export default {
  name: 'Login',
  mixins: [RulesMixin],
  components: { ResetPassword },
  data: () => ({
    showReset: false,
    loading: false,
    formValid: true,
    showPassword: false,
    email: null,
    password: null
  }),
  computed: {
    version () {
      return process.env.VUE_APP_BUILD || 'No build'
    }
  },
  methods: {
    login () {
      this.$refs.form.validate()
      if (this.formValid === false) {
        return
      }

      this.loading = true
      this.$store
        .dispatch('User/login', { email: this.email, password: this.password, token: this.$route.query.token })
        .then(() => {
          this.$router.push({ name: 'Dashboard' })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
