<template>
  <v-dialog :value="open" max-width="500" persistent>
    <v-card>
      <v-card-title>
        Reset Password
        <v-spacer />
        <v-btn icon @click="$emit('close')">
          <v-icon>
            fa-times
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="email"
          label="Email"
          @keydown.enter="sendReset"
          :error-messages="fieldErrors"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="sendReset" :loading="loading">
          <v-icon small left>
            fa-envelope
          </v-icon>
          Send Reset
        </v-btn>
        <v-btn color="secondary" @click="$emit('close')">
          <v-icon small left>
            fa-times
          </v-icon>
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { email, required } from 'vuelidate/lib/validators'
import event, { Events } from '../../event'

export default {
  props: {
    open: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data: () => ({
    email: '',
    loading: false
  }),
  validations: {
    email: {
      email,
      required
    }
  },
  computed: {
    fieldErrors () {
      const errors = []
      if (!this.$v.$dirty) { return errors }
      if (!this.$v.email.required) { errors.push('Email address is required') }
      if (!this.$v.email.email) { errors.push('Email address is invalid') }

      return errors
    }
  },
  methods: {
    sendReset () {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      this.loading = true
      this.$axios.post('/v1/user/reset', { email: this.email })
        .then(() => {
          event.emit(Events.SUCCESS, 'Password reset sent!')
          this.$emit('close')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
